import { Suspense, lazy, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import ActivityIndicator, { ActivityIndicatorType } from '../components/ActivityIndicator/ActivityIndicator';
import { LoginRoutes } from './LoginRoutes';
import { TestRoutes } from './TestRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { PartnerRegionSpecificRoutes } from './PartnerRegionSpecificRoutes';

/* istanbul ignore next: No need to codecov this import */
/* istanbul ignore next: No need to codecov this import */
/* istanbul ignore next: No need to codecov this import */
/* istanbul ignore next: No need to codecov this import */
const Unauthorized = lazy(() => import('../views/errors/401'));
const PartnerUnauthorized = lazy(() => import('../views/errors/PartnerError'));
const RejectPartnerConsentsView = lazy(() => import('../views/reject-partner-consents/RejectPartnerConsents'));
const NoMatch = lazy(() => import('../views/errors/404'));
const TeaPot = lazy(() => import('../views/errors/418'));
const ServerError = lazy(() => import('../views/errors/500'));
/* istanbul ignore next: No need to codecov this import */

// export interface IAppRouterProps {
export type ErrorCallback = (error: any, currentPartner?: string, currentPartnerRegion?: string) => void;
export type RedirectCallback = (path: string) => void;

export interface IAppCallBackProps {
  onErrorCallback?: ErrorCallback;
  onRedirectCallback?: RedirectCallback;
}

export interface IAppRouterProps extends IAppCallBackProps {
  partnerRegionRouteConfigs: RouteConfig[];
  children?: any;
}

export interface RouteConfig {
  partner: string;
  partnerRegion: string;
}
const RouterComponentSwitcher = (props: IAppRouterProps): ReactElement => {
  return (
    <Suspense fallback={<ActivityIndicator type={ActivityIndicatorType.FullPage} />}>
      <Switch>
        {/* Fragments & Element arrays not supported by Switch, so we use a map instead */}
        {PartnerRegionSpecificRoutes(props).map((el) => {
          return el;
        })}
        {PrivateRoutes(props).map((el) => {
          return el;
        })}
        {TestRoutes()?.map((el) => {
          return el;
        })}
        {LoginRoutes(props).map((el) => {
          return el;
        })}
        <Route path="/auth/callback">
          <ActivityIndicator type={ActivityIndicatorType.FullPage} />
        </Route>
        ,
        <Route path="/401" component={Unauthorized} />,
        <Route path="/partner-error" component={PartnerUnauthorized} />,
        <Route path="/418" component={TeaPot} />,
        <Route path="/500/uber-pro/us" component={ServerError} />,
        <Route path="/reject-partner-consents" component={RejectPartnerConsentsView} />,
        <Route path="/500/uber-pro/au" component={ServerError} />,
        <Route path="/500/uber-pro/nl" component={ServerError} />,
        <Route path="/500/uber-pro/uk" component={ServerError} />,
        <Route path="/500" component={ServerError} />,
        <Route path="/500/amazon-prime/us" component={ServerError} />,
        <Route component={NoMatch} />
      </Switch>
    </Suspense>
  );
};

export default RouterComponentSwitcher;
